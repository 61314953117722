<template>
    <!-- Modal -->
    <div class="modal fade" id="registrationModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header border-0">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">{{ $t('registration.header')  }}</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <Form  class="modal-body" @submit="submit" :validation-schema="schema" v-slot="{ errors }">
                    <div class="border-top p-2">
                        <Message v-if="msg.length > 0 & !msgStatus" severity="error">{{ msg }}</Message>
                        <Message v-else-if="msg.length > 0 & msgStatus" severity="success">{{ msg }}</Message>
                        
                            <div class="row mt-3">
                            <div class="col-md-6 mb-3">
                                <label for="firstname">{{ $t("registration.firstname") }}</label>
                                <Field type="text" name="firstname" class="form-control" :class="{ 'is-invalid': errors.firstname }"/>
                                <div class="invalid-feedback">{{errors.firstname}}</div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="lastname">{{ $t("registration.lastname") }}</label>
                                <Field type="text" class="form-control" name="lastname" :class="{ 'is-invalid': errors.lastname }"/>
                                <div class="invalid-feedback">{{errors.lastname}}</div>
                            </div>
                            </div>
                            <div class="row">
                            <div class="col-md-6 mb-3">
                                <label for="affiliation">{{ $t("registration.affiliation") }}</label>
                                <Field type="text" class="form-control" name="affiliation" :class="{ 'is-invalid': errors.affiliation }"/>
                                <div class="invalid-feedback">{{errors.affiliation}}</div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="affiliation"></label>
                                <Field name="country" v-model="country">
                                <country-select as="select" :usei18n="false" :placeholder="$t('registration.countrySelect')"
                                    :class="{ 'is-invalid': errors.country }" class="form-select" v-model="country"
                                    :country="country" :autocomplete="true" :disablePlaceholder="true"/>
                                </Field>
                                <div class="invalid-feedback">{{errors.country}}</div>
                            </div>

                            </div>
                            <div class="row">
                            <div class="col-md-6 mb-3">
                                <label for="email">{{ $t("registration.email") }}</label>
                            <Field type="email" class="form-control" name="email" :class="{ 'is-invalid': errors.email }" autocomplete="email"/>
                            <div class="invalid-feedback">{{errors.email}}</div>
                            </div>
                            </div>


                            <div class="row">
                            <div class="col-md-6 mb-3">
                                <label for="password">{{ $t("registration.password") }}</label>
                                <div class="input-group">
                                <Field v-if="showPassword" type="text" class="form-control" name="password" :class="{ 'is-invalid': errors.password }" autocomplete="new-password"/>
                                <Field v-else type="password" class="form-control" name="password" :class="{ 'is-invalid': errors.password }" autocomplete="new-password"/>
                                <span class="input-group-text" type="button" @click="togglePassword">
                                    <font-awesome-icon v-if="!showPassword" icon="fa-solid fa-eye" />
                                    <font-awesome-icon v-else icon="fa-solid fa-eye-slash" />
                                </span>
                                </div>
                                
                                <div class="invalid-feedback">{{errors.password}}</div>
                            </div>
                            <div class="col-md-6 mb-3 ">
                                <label for="confirmPassword">{{ $t("registration.reppassword") }}</label>
                                <div class="input-group">
                                <Field v-if="showPassword" type="text" class="form-control" name="confirmPassword" :class="{ 'is-invalid': errors.confirmPassword }" autocomplete="new-password"/>
                                <Field v-else type="password" class="form-control" name="confirmPassword" :class="{ 'is-invalid': errors.confirmPassword }" autocomplete="new-password"/>

                                <span class="input-group-text" type="button" @click="togglePassword">
                                    <font-awesome-icon v-if="!showPassword" icon="fa-solid fa-eye" />
                                    <font-awesome-icon v-else icon="fa-solid fa-eye-slash" />
                                </span>
                                </div>
                                
                                <div class="invalid-feedback">{{errors.confirmPassword}}</div>
                            </div>
                            </div>
                            
                    </div>
                    <div class="modal-footer p-2 d-flex justify-content-between">
                        <div  data-bs-toggle="modal" data-bs-target="#loginModal" class="btn btn-outline-orange mt-3" id="login-btn">Login</div>
                        <button type="submit" class="btn btn-outline-orange d-flex align-items-center mt-3">
                            <span class="me-1">{{ $t("registration.header") }}</span>
                            <i class="pi pi-angle-double-right"></i>
                        </button>
                        </div>
                    </Form>

                </div>
              </div>
            </div>
</template>


<script>
import { Form, Field} from 'vee-validate';
import * as Yup from 'yup';
import Tr from "@/i18n/translation"
import i18n from '@/i18n'
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import {  mapActions } from 'vuex';
import ModalLongScroll from '@/components/ModalLongScroll.vue'

import axios from 'axios'

 export default {
      name: 'RegistrationModal',
      components:{
        Form,
        Field,
        ModalLongScroll
      },
      emits: ["close"],
      data() { 
        const schema = Yup.object().shape({
            firstname: Yup.string()
                .required(() => i18n.global.t('err.firstname')),
            lastname: Yup.string()
                .required(() => i18n.global.t('err.lastname')),
            affiliation: Yup.string()
                .required(() => i18n.global.t('err.affiliation')),
            country: Yup.string()
                .required(() => i18n.global.t('err.country')),
            email: Yup.string().required(() => i18n.global.t('err.email')),
            password: Yup.string().min(8, "Length").required(() => i18n.global.t('err.password')),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], () => i18n.global.t('err.confirmPassword'))
                .required(() => i18n.global.t('err.confirmPassword2')),
        });
        return {
          schema,
          msg: [],
          msgStatus: false,
          country: "",
          showPassword: false,
        }
      },
      setup(props, {emit}) {
        const target = ref(null);
        const handleClickOutside = () => {
          emit('close');
        };
    
        onClickOutside(target, handleClickOutside);
        
        return {  Tr, target};
      },
      methods: {
        ...mapActions(['openModal', 'closeModal']),
        submit(values){
          axios
          // .post('http://localhost/rota/backend/registration.php', values) 
          .post('/rota/backend/registration.php', values)
          .then(response => {
            this.msg = i18n.global.t(response.data["msg"])
            this.msgStatus = response.data["msg-status"]

            if (this.msgStatus) {
              this.$store.dispatch('loginUser', {email: values.email})
            }
            })
        },
        togglePassword(){
          this.showPassword = !this.showPassword;
        }
      }
 }

</script>

<style>


</style>