import { createRouter, createWebHistory, createWebHashHistory, RouterView } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Tr from "@/i18n/translation"
import store from '@/store/index.js';
import { mapGetters, mapActions } from 'vuex';

const router = createRouter({
  history: createWebHashHistory(process.env.VUE_APP_BASE_URL), //process.env.VUE_APP_BASE_URL
  routes: [
    {
      path: "/:locale?",
      component: RouterView,
      beforeEnter: Tr.routeMiddleware,
      children: [
        {
          path: '',
          name: 'home',
          component: HomeView
        },
        {
          path: 'corpus',
          name: 'about',
          component: () => import('../views/AboutView.vue'),
          meta: {auth: false}
        },
        {
          path: 'persons',
          name: 'persons',
          component: () => import('../views/PersonsView.vue'),
          meta: {auth: false}
        },
        {
          path: 'search',
          name: 'search',
          component: () => import('../views/SucheView.vue'),
          meta: {auth: true}
        },
        {
          path: 'imprint',
          name: 'imprint',
          component: () => import('../views/ImpressumView.vue'),
          meta: {auth: false}
        },
        {
          path: 'contact',
          name: 'contact',
          component: () => import('../views/KontaktView.vue'),
          meta: {auth: false}
        },
        {
          path: 'password-reset/:token',
          name: 'password-reset',
          component: () => import('../views/PasswordReset.vue'),
          props: true,
          meta: {auth: false},
          beforeEnter(to, from, next) {
            store.dispatch('closeAllModals');

            next()
            
          },
        },
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  if(to.meta.auth && !store.state.email){
    store.dispatch('openModal', {modalName: 'Login', msg: true});
  }else{
    next()
  }
})



export default router
